@import "nlib/config";

.dragAndDropArea {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: $contentDefaultIndent;
  border: 1px solid $uiNeutralColor;
  border-radius: $uiBorderRadius;
  background-color: $uiWhiteColor;
  color: $uiNeutralColor;
  cursor: pointer;
  &:not([disabled]) {
    border-style: dashed;
    &:hover {
      background-color: $uiBackgroundColor;
    }
  }
  &.success {
    border-color: $uiPositiveColor;
    color: $uiPositiveColor;
  }
  &.error {
    border-color: $uiNegativeColor;
    color: $uiNegativeColor;
  }
  &[disabled] {
    border-color: rgba($uiNeutralColor, 0.5);
    color: rgba($uiNeutralColor, 0.5);
    cursor: default;
  }
  &.dragOver {
    border-color: $uiPrimaryColor;
    color: $uiPrimaryColor;
    .overlay {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .fileInput {
    display: none !important;
  }
}
