$appHeaderHeight: 62px;
$contentDefaultIndent: 8px;
$uiBorderRadius: $contentDefaultIndent / 2;
$navBarFullWidth: 220px;
$maxSupportedScreenWidth: 1920px;
$minContentWidth: $maxSupportedScreenWidth - $contentDefaultIndent * 4 - $navBarFullWidth;
$maxContainerWidth: 1668px;
$maxMobileContainerWidth: 1024px;
$maxMobileFixedButtonWidth: 512px;
$mobileNavBarHeight: 4.75rem;
$menuFullWidthMinViewport: 1600px;
$viewportSizeExtraLarge: 1200px;
$viewportSizeLarge: 992px;
$viewportSizeMedium: 768px;
$viewportSizeSmall: 576px;
$viewportSizeExtraSmall: 420px;
$fullWidth: 100%;
$transitionDuration: 0.15s;
$uiComponentDisabledOpacity: 0.5;
$uiMobileFontSize: 0.875rem;
$uiDesktopFontSize: 0.8125rem;
$uiFontFamily: "Roboto", sans-serif;
$uiBorderWidth: 0.0625rem;
$uiWhiteColor: #fff;
$uiNeutralColor: #becad6;
$uiMediumDarkColor: #8c96a0;
$uiDarkColor: #666979;
$uiFontColor: #262627;
$uiBlackColor: #000;
$uiLightBlueColor: #24adff;
$uiPrimaryColor: #0267d4;
$uiHighlightColor: #0267d4;
$uiAlternativeColor: #7502d4;
$uiAccentColor: #ff4169;
$uiPositiveColor: #12ab6b;
$uiNegativeColor: #e24e45;
$uiWarningColor: #ffad31;
$uiBorderColor: #eaebee;
$uiSpecialColor: #9845f7;
$uiPlaceholderColor: darken($uiNeutralColor, 6%);
$uiBackgroundColor: #f6f6f9;
$uiOverlayColor: #003166;
