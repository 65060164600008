@import "nlib/config";

.toastContainer {
  opacity: 0.95;
  .toast {
    border-radius: $contentDefaultIndent;
    word-break: break-word;
  }
}

.overlayPreloader {
  z-index: 1100 !important;
}

[data-mobile] {
  .toastContainer {
    padding: 0.5rem;
    .toast {
      margin-bottom: $contentDefaultIndent;
    }
  }
}
