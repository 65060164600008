@import "nlib/config";

.autoTooltip {
  display: inline-block;
  position: relative;
  max-width: 100%;
  &.active {
    &:hover {
      .content {
        opacity: 0;
      }
    }
  }
  &:not(:hover) {
    overflow: hidden;
  }
  .content {
    box-sizing: initial;
    white-space: nowrap;
  }
  .content,
  .tooltip {
    box-sizing: content-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tooltip {
    display: none;
    position: absolute;
    z-index: 19;
    top: 0;
    left: 0;
    width: max-content;
    min-width: 100%;
    max-width: 20em;
    margin: (-$contentDefaultIndent / 2) (-$contentDefaultIndent);
    padding: $contentDefaultIndent / 2 $contentDefaultIndent;
    transform: translateZ(0);
    border-radius: $uiBorderRadius;
    background: $uiWhiteColor;
    box-shadow: 0 0.125rem 0.75rem rgba($uiBlackColor, 0.1);
    white-space: pre-line;
  }
  &:hover {
    .tooltip {
      display: block;
    }
  }
}
