@import "nlib/config";

.datePicker {
  [disabled] {
    opacity: 1 !important;
  }
  .clear {
    display: flex;
    position: absolute;
    top: 50%;
    right: $contentDefaultIndent;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate(0, -50%);
    opacity: 0.5;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}
.datePickerDropDown {
  display: flex;
  width: auto;
  user-select: none;
  .mainContent {
    display: flex;
    padding: $contentDefaultIndent $contentDefaultIndent * 2;
  }
}

[data-mobile]:not([data-old-ui]) {
  .datePickerDropDown {
    flex-direction: column;
    width: 20rem !important;
  }
}
