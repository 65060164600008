@import "nlib/config";

.popup {
  position: relative;
  position: sticky;
  z-index: 5;
  bottom: $contentDefaultIndent * 2;
  width: fit-content;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  .animationEnter {
    transform: translate3d(0, 2rem, 0);
    opacity: 0;
  }
  .animationEnterActive,
  .animationExit {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  .animationExitActive {
    transform: translate3d(0, 2rem, 0);
    opacity: 0;
  }
  .animationEnterActive,
  .animationExitActive {
    transition: opacity 300ms, transform 300ms;
  }
}
