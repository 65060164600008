@import "nlib/config";

.badge {
  display: inline-block;
  align-items: center;
  height: 1.25rem;
  padding: 0 $contentDefaultIndent * 0.75;
  overflow: hidden;
  border-radius: $uiBorderRadius;
  background-color: rgba($uiDarkColor, 0.1);
  color: $uiDarkColor;
  font-size: 0.6875rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.counter {
    min-width: 21px;
    height: $contentDefaultIndent * 2;
    padding: 0 0.25rem;
    border-radius: 0.5625rem;
    line-height: $contentDefaultIndent * 2;
    text-align: center;
  }
  &.dark {
    background-color: $uiDarkColor;
    color: $uiWhiteColor;
  }
  &.attention {
    background-color: $uiNegativeColor;
    color: $uiWhiteColor;
  }
  &.primary {
    background-color: lighten($uiPrimaryColor, 54%);
    color: $uiPrimaryColor;
  }
  &.negative {
    background-color: lighten($uiNegativeColor, 36%);
    color: $uiNegativeColor;
  }
  &.positive {
    background-color: lighten($uiPositiveColor, 58%);
    color: $uiPositiveColor;
  }
  &.warning {
    background-color: lighten($uiWarningColor, 33%);
    color: $uiWarningColor;
  }
  &.alternative {
    background-color: lighten($uiAlternativeColor, 53%);
    color: $uiAlternativeColor;
  }
  &.inverted {
    &.primary {
      background-color: $uiPrimaryColor;
      color: $uiWhiteColor;
    }
    &.negative {
      background-color: $uiNegativeColor;
      color: $uiWhiteColor;
    }
    &.positive {
      background-color: $uiPositiveColor;
      color: $uiWhiteColor;
    }
    &.warning {
      background-color: $uiWarningColor;
      color: $uiWhiteColor;
    }
    &.alternative {
      background-color: $uiAlternativeColor;
      color: $uiWhiteColor;
    }
  }
  &.outline {
    background-color: $uiWhiteColor;
    box-shadow: inset 0 0 0 1px $uiBorderColor;
  }
}
