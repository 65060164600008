@import "nlib/config";

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: 1.5rem;
  margin: $contentDefaultIndent / 4;
  padding-right: $contentDefaultIndent / 4;
  padding-left: $contentDefaultIndent * 0.75;
  overflow: hidden;
  transition: background-color 0.1s;
  border-radius: $uiBorderRadius / 2;
  background-color: $uiDarkColor;
  color: $uiWhiteColor;
  font-weight: normal;
  line-height: 1;
  cursor: pointer;
  &:hover,
  &.highlight {
    background-color: $uiNegativeColor;
  }
  .content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }
  .remove {
    margin-left: 0.3em;
    cursor: pointer;
    svg {
      display: block;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  &.disabled {
    background-color: #e0e0e0;
    color: #4f4f4f;
    .remove {
      cursor: default;
      &:hover {
        opacity: 1;
      }
    }
  }
}

[data-mobile] {
  .item {
    height: 2rem;
  }
}
