@import "nlib/config";

$tableBorderColor: darken($uiBorderColor, 0);

.table {
  display: flex;
  flex: auto 1;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-radius: $uiBorderRadius;
  background-color: $uiWhiteColor;
  font-size: 0.75rem;
  .tableCell {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    padding: $contentDefaultIndent $contentDefaultIndent * 2;
  }
  .tableHead {
    min-height: 2.75rem;
    background-color: $uiWhiteColor;
    color: $uiDarkColor;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    white-space: nowrap;
    user-select: none;
    .sortIcons {
      margin-left: $contentDefaultIndent / 2;
      color: $uiBlackColor;
      font-size: 0.75rem;
    }
    &.active {
      color: $uiPrimaryColor;
      svg {
        color: $uiPrimaryColor;
      }
    }
    &.sortable {
      cursor: pointer;
    }
    .title {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &:not(.flat) {
    .tableHead {
      border-top: 1px solid $uiBorderColor;
      &:first-child {
        border-top-left-radius: $uiBorderRadius;
      }
      &:last-child {
        border-top-right-radius: $uiBorderRadius;
      }
    }
    .tableCell {
      border-bottom: 1px solid $uiBorderColor;
      &:first-child {
        border-left: 1px solid $uiBorderColor;
      }
      &:last-child {
        border-right: 1px solid $uiBorderColor;
      }
    }
  }
  .tableRow {
    display: flex;
  }
  &.hover {
    > .tableRow {
      &:hover {
        > .tableCell:not(.tableHead) {
          background-color: rgba(0, 0, 0, 0.03);
        }
      }
    }
  }
  &.flat {}
}

.tableRow {
  display: flex;
  &:first-child {
    border-top-left-radius: $uiBorderRadius;
    border-top-right-radius: $uiBorderRadius;
    .tableCell {
      &:first-child {
        border-top-left-radius: $uiBorderRadius;
      }
      &:last-child {
        border-top-right-radius: $uiBorderRadius;
      }
    }
  }
  &:last-child {
    border-bottom-left-radius: $uiBorderRadius;
    border-bottom-right-radius: $uiBorderRadius;
    .tableCell {
      &:first-child {
        border-bottom-left-radius: $uiBorderRadius;
      }
      &:last-child {
        border-bottom-right-radius: $uiBorderRadius;
      }
    }
  }
  &:nth-child(even) {
    background-color: lighten($uiNeutralColor, 19%);
  }
}

[data-borderless] {
  .tableCell {
    border: none !important;
  }
  .tableHead {
    border-bottom: 1px solid $tableBorderColor !important;
  }
}
[data-compact] {
  .tableCell {
    padding: $contentDefaultIndent;
  }
}
