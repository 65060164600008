@import "nlib/config";

.dropDownContent {
  position: absolute;
  z-index: 21;
  left: 0;
  margin-top: $contentDefaultIndent / 2;
  margin-bottom: $contentDefaultIndent / 2;
  overflow: auto;
  border-radius: $uiBorderRadius;
  background-color: $uiWhiteColor;
  box-shadow:
    0 0.5rem 4rem rgba($uiBlackColor, 0.11),
    0 10px 20px rgba($uiBlackColor, 0.05),
    0 2px 3px rgba($uiBlackColor, 0.06);
  *::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: $uiNeutralColor;
    &:active {
      background-color: $uiPrimaryColor;
    }
  }
  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: $uiBorderColor;
  }
  &.alignRight {
    right: 0;
    left: auto;
  }
  &.modal {
    position: fixed;
    z-index: 22;
    top: 50%;
    left: 50%;
    width: auto;
    min-width: 18rem;
    margin: 0;
    padding: 0;
    transform: translate(-50%, -50%) !important;
    .content {
      padding: 0 $contentDefaultIndent * 2 $contentDefaultIndent * 2;
    }
  }
  &.portal {
    position: fixed;
    z-index: 1050;
  }
  .header {
    display: flex;
    .title {
      display: flex;
      flex: auto;
      align-items: center;
      height: 3rem;
      padding-left: $contentDefaultIndent * 2;
      overflow: hidden;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      height: 3rem;
      svg {
        font-size: 1.25rem;
      }
    }
  }
}
.dropDownOverlay {
  position: fixed;
  z-index: 21;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($uiOverlayColor, 0.3);
}

[data-mobile]:not([data-old-ui]) {
  .dropDownContent {
    &.portal.centered {
      right: auto !important;
      left: 50% !important;
      width: 32rem;
      min-width: auto;
      transform: translate(-50%, 0);
    }
    &.relative {
      width: 100%;
    }
    .header {
      position: sticky;
      z-index: 1;
      top: 0;
      background-image: linear-gradient(to top, transparent, $uiWhiteColor 8px);
      .title {
        padding-left: $contentDefaultIndent * 2;
      }
    }
  }
}
