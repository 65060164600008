@import "nlib/config";

.pageSize {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-weight: 500;
  .select {
    width: 100px;
    margin-left: 0.3em;
    button {
      height: 2rem !important;
    }
  }
}
