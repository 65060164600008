@import "nlib/config";

.datePickerHeader {
  display: flex;
  margin-bottom: $contentDefaultIndent;
  padding-bottom: $contentDefaultIndent;
  box-shadow: 0 1px 0 $uiBorderColor;
  .prev,
  .next,
  .label {
    display: flex;
    align-items: center;
    height: 3.5rem;
    user-select: none;
  }
  .prev,
  .next {
    color: $uiPrimaryColor;
    font-size: 1.5rem;
    cursor: pointer;
    &:hover {
      color: lighten($uiPrimaryColor, 10%);
    }
    &:active {
      color: darken($uiPrimaryColor, 5%);
    }
    svg {
      display: block;
      pointer-events: none;
    }
  }
  .prev {
    padding-right: 1rem;
  }
  .next {
    padding-left: 1rem;
  }
  .label {
    flex: 1;
    justify-content: center;
    color: $uiFontColor;
    font-size: 1.125rem;
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;
    &.pointer {
      cursor: pointer;
    }
    &:hover {
      color: lighten($uiFontColor, 20%);
    }
    &:active {
      color: darken($uiFontColor, 5%);
    }
  }
}
