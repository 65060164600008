@import "nlib/config";

.switcher {
  display: flex;
  width: 100%;
  padding: $contentDefaultIndent / 2;
  border-radius: $uiBorderRadius;
  background-color: $uiBorderColor;
  .button {
    flex: 1;
    + button {
      margin-left: $contentDefaultIndent / 2;
    }
    &.active {
      cursor: default;
      pointer-events: none;
      &:hover {
        background-color: $uiWhiteColor;
      }
    }
  }
  &.light {
    padding: 0;
    background-color: transparent;
    .button {
      font-weight: normal;
      &.active {
        background-color: rgba($uiPrimaryColor, 0.1);
        font-weight: 500;
      }
    }
  }
}
[data-mobile]:not([data-old-ui]) {
  .switcher {
    background-color: $uiBorderColor;
    .button {
      height: 2.5rem;
    }
  }
}
