@import "nlib/config";

.maintenanceHandler {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex: auto;
  padding: 1em;
  .content {
    max-width: 27rem;
    text-align: center;
    .iconContainer {
      position: relative;
      .icon {
        color: $uiMediumDarkColor;
        font-size: 8rem;
      }
      .extraIcon {
        position: absolute;
        top: $contentDefaultIndent * 1.5;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        font-size: 3rem;
      }
      .semiTransparent {
        opacity: 0.3;
      }
      .warning {
        color: $uiWarningColor;
      }
      .positive {
        color: $uiPositiveColor;
      }
    }
    .title {
      font-size: 1.5rem;
      word-break: break-word;
    }
    .description {
      margin-top: 0.5rem;
      padding: 0 0.5rem;
      color: $uiMediumDarkColor;
      font-size: 0.9rem;
    }
    .buttonContainer {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      > button {
        flex: 1;
        max-width: 15rem;
        margin: 0.5rem;
      }
    }
  }
}

@media (max-width: 512px) {
  .authHandler {
    .content {
      .title {
        font-size: 1.25rem;
      }
      .buttonContainer {
        flex-wrap: wrap;
      }
    }
  }
}

@media (max-width: 360px) {
  .authHandler {
    .content {
      .buttonContainer {
        > button {
          min-width: 100%;
        }
      }
    }
  }
}
