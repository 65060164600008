@import "nlib/config";

.input {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  font-size: $uiDesktopFontSize;
  input,
  textarea {
    appearance: none;
    display: block;
    width: 100%;
    padding: 0 0.75rem;
    border: 0.0625rem solid $uiBorderColor;
    border-radius: $uiBorderRadius;
    outline: none;
    background-color: $uiWhiteColor;
    color: $uiFontColor;
    font-family: $uiFontFamily;
    font-size: 1em;
    &:hover:not(:focus):not(.focused):not([disabled]):not([readonly]) {
      background-color: lighten($uiBorderColor, 5%);
    }
    &[readonly],
    &[disabled] {
      opacity: 1 !important;
      background-color: $uiBackgroundColor;
    }
  }
  &[disabled] {
    opacity: 1 !important;
  }
  &:not(.error) {
    input,
    textarea {
      &:focus,
      &.focused,
      &.active {
        &:not([readonly]) {
          border-color: $uiPrimaryColor;
          ~ .iconBefore {
            color: $uiPrimaryColor;
          }
        }
      }
      &:focus,
      &.focused {
        &:not([readonly]) {
          position: relative;
        }
      }
    }
  }
  input {
    height: 2.5rem;
  }
  textarea {
    min-height: 5rem;
    padding-top: $contentDefaultIndent;
    padding-bottom: $contentDefaultIndent;
    resize: vertical;
  }
  &.noResize {
    textarea {
      resize: none;
    }
  }
  .iconBefore {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    height: 100%;
    padding-left: $contentDefaultIndent * 1.5;
    color: $uiPlaceholderColor;
    font-size: 1rem;
    pointer-events: all;
  }
  .iconAfter {
    display: flex;
    position: absolute;
    top: 50%;
    right: $contentDefaultIndent;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate(0, -50%);
    opacity: 0.5;
    font-size: 1rem;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  &.withIconBefore {
    input {
      padding-left: 2.125rem;
    }
    textarea {
      padding-right: 1.5rem;
      + .iconBefore {
        top: 0;
        right: 0;
        left: auto;
        height: 2.5rem;
        padding-right: $contentDefaultIndent * 1.5;
        padding-left: 0;
      }
    }
  }
  &:not([readonly]) {
    &.active {
      .iconBefore,
      .iconAfter {
        svg {
          color: $uiHighlightColor;
        }
      }
    }
    &.valid {
      .iconBefore,
      .iconAfter {
        svg {
          display: block;
          color: $uiPositiveColor;
        }
      }
    }
    &.warning {
      .iconBefore,
      .iconAfter {
        svg {
          color: $uiWarningColor;
        }
      }
    }
    &.invalid {
      .iconBefore,
      .iconAfter {
        svg {
          color: $uiNegativeColor;
        }
      }
    }
    &.error {
      input,
      textarea {
        border-color: $uiNegativeColor;
        &:focus,
        &.focused {
          &:not([readonly]) {
            box-shadow: 0 0 0 0.1875rem rgba($uiNegativeColor, 0.1);
          }
        }
      }
    }
    &.highlight {
      .iconBefore {
        svg {
          color: $uiPrimaryColor;
        }
      }
    }
  }

  &.sizeSmall {
    input,
    textarea {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      font-size: 0.75rem;
      line-height: 1rem;
    }
    input {
      height: 2rem;
    }
    .iconBefore {
      padding-left: $contentDefaultIndent;
    }
    &.withIconBefore {
      input {
        padding-left: 1.625rem;
      }
      textarea {
        padding-right: 1.5rem;
        + .iconBefore {
          height: 2rem;
          padding-right: $contentDefaultIndent;
        }
      }
    }
  }
  &.borderPrimary {
    input,
    textarea {
      border-color: $uiPrimaryColor;
    }
  }
  &.borderSuccess {
    input,
    textarea {
      border-color: $uiPositiveColor;
    }
  }
  &.borderWarning {
    input,
    textarea {
      border-color: $uiWarningColor;
    }
  }
  &.borderDanger {
    input,
    textarea {
      border-color: $uiNegativeColor;
    }
  }
}
[data-mobile]:not([data-old-ui]) {
  .input {
    input {
      height: 3rem;
    }
    input,
    textarea {
      border-width: 0.125rem;
      font-size: $uiMobileFontSize;
    }
  }
}
