@import "nlib/config";

.modal {
  display: flex;
  position: fixed;
  z-index: 22;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: fit-content;
  padding: $contentDefaultIndent * 2;
  overflow: auto;
  animation: uiDialogBgAnimation 0.25s forwards;
  background-color: rgba($uiOverlayColor, 0);
  will-change: background-color;
  overscroll-behavior: contain;
  &.noOverlay {
    animation: none;
  }
  &.disabled {
    .dialog {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($uiOverlayColor, 0.3);
      }
    }
  }
  .dialog {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 32rem;
    max-width: 100%;
    margin: auto;
    animation: uiDialogWindowAnimation 0.25s forwards;
    border-radius: $uiBorderRadius;
    opacity: 0;
    background-color: $uiWhiteColor;
    box-shadow: 0 0 3rem 0 rgba($uiBlackColor, 0.15);
    will-change: opacity;
    &.sm {
      width: 24rem;
    }
    &.md {
      width: 42rem;
    }
    &.lg {
      width: 40rem;
    }
    &.xl {
      width: 48rem;
    }
    .header {
      display: flex;
      position: relative;
      align-items: center;
      min-height: 4rem;
      padding-right: $contentDefaultIndent * 8;
      padding-left: $contentDefaultIndent * 3;
      .title {
        display: flex;
        align-items: center;
        overflow: hidden;
        font-size: 1rem;
        font-weight: bold;
        white-space: nowrap;
        svg {
          margin-right: 0.3em;
          color: $uiDarkColor;
          font-size: 1.25rem;
          vertical-align: text-bottom;
        }
        .text {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .close {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        cursor: pointer;
        svg {
          font-size: 1.25rem;
        }
        &:hover {
          opacity: 0.75;
        }
      }
    }
    .content {
      height: 100%;
      padding: 0 $contentDefaultIndent * 3 $contentDefaultIndent * 3;
      line-height: 1.125rem;
    }
    .buttons {
      display: flex;
      justify-content: center;
      padding: $contentDefaultIndent * 2 $contentDefaultIndent * 3;
      button + button {
        margin-left: $contentDefaultIndent * 2;
      }
    }
  }
}
[data-mobile]:not([data-old-ui]) {
  .modal {
    min-width: 0;
    .dialog {
      max-height: none;
      .content {
        padding: 0 $contentDefaultIndent * 3 $contentDefaultIndent * 2;
      }
      .buttons {
        padding: $contentDefaultIndent * 2 $contentDefaultIndent * 2;
      }
    }
  }
}
@keyframes uiDialogBgAnimation {
  from {
    background-color: rgba($uiOverlayColor, 0);
  }
  to {
    background-color: rgba($uiOverlayColor, 0.3);
  }
}
@keyframes uiDialogWindowAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
