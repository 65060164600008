@import "nlib/config";

.datePickerDay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $fullWidth / 7;
  height: 2.375rem;
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
  font-size: 0.875rem;
  cursor: pointer;
  user-select: none;
  &.today {
    box-shadow: 0 0 0 2px rgba($uiPrimaryColor, 0.2);
  }
  &.selected,
  &.rangeEnd {
    border-radius: $uiBorderRadius;
    background-color: $uiPrimaryColor;
    color: $uiWhiteColor;
  }
  &.highlighted {
    background-color: rgba($uiPrimaryColor, 0.09);
  }
  &.muted,
  &.disabled {
    opacity: 0.3;
    cursor: default;
  }
  &:not(.muted):not(.disabled) {
    &:hover {
      background-color: rgba($uiBorderColor, 0.5);
      &.selected,
      &.rangeEnd {
        background-color: rgba($uiPrimaryColor, 0.9);
      }
      &.highlighted {
        background-color: rgba($uiPrimaryColor, 0.12);
      }
    }
  }
}
