@import "nlib/config";

.preloader {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  color: $uiPrimaryColor;
  font-size: 4rem;
  &.absolute,
  &.fixed {
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($uiWhiteColor, 0.4);
  }
  &.absolute {
    position: absolute;
    z-index: 3;
  }
  &.fixed {
    position: fixed;
    z-index: 3;
  }
  &.opaque {
    background-color: $uiWhiteColor;
  }
  .animated {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateZ(0);
    svg {
      width: 1em;
      animation: rotate 0.8s linear infinite;
      font-size: 1em;
    }
  }
  .content {
    padding: 0.5em $contentDefaultIndent * 2 $contentDefaultIndent * 2;
    color: $uiDarkColor;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
  }
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
