@import "nlib/config";

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  overflow: hidden;
  border-radius: 50%;
  background-color: $uiBackgroundColor;
  color: $uiWhiteColor;
  .abbr {
    font-size: 1rem;
    line-height: 1;
    cursor: default;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
  svg {
    font-size: 1.5em;
  }
}
