@import "nlib/config";

.tooltip {
  position: absolute;
  z-index: 1;
  width: 0;
  height: 0;
  .content {
    position: absolute;
    padding: $contentDefaultIndent / 2 $contentDefaultIndent;
    border-radius: $uiBorderRadius;
    background-color: $uiWhiteColor;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08), -2px 2px 4px rgba(0, 0, 0, 0.08);
    font-size: $uiDesktopFontSize;
    font-weight: 500 !important;
    word-wrap: break-word;
  }
  .arrow {
    position: absolute;
    top: 0;
    left: 0;
    border-style: solid;
    border-color: $uiWhiteColor;
  }
  &.simple {
    .arrow {
      display: none;
    }
  }
  &.top {
    bottom: 100%;
    left: 50%;
    margin-bottom: $contentDefaultIndent;
    .content {
      transform: translate(-50%, -100%);
    }
    .arrow {
      transform: translate(-50%, -1px);
      border-width: 5px 5px 0;
      border-color: $uiWhiteColor transparent transparent;
      filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.2));
    }
  }
  &.bottom {
    top: 100%;
    left: 50%;
    margin-top: $contentDefaultIndent;
    .content {
      transform: translate(-50%, 0);
    }
    .arrow {
      transform: translate(-50%, -100%);
      border-width: 0 5px 5px;
      border-color: transparent transparent $uiWhiteColor;
      filter: drop-shadow(0 -1px 1px rgba(0, 0, 0, 0.1));
    }
  }
  &.right {
    top: 50%;
    left: 100%;
    margin-left: $contentDefaultIndent;
    .content {
      transform: translate(0, -50%);
    }
    .arrow {
      transform: translate(-100%, -50%);
      border-width: 5px 5px 5px 0;
      border-color: transparent $uiWhiteColor transparent transparent;
      filter: drop-shadow(-1px 0 1px rgba(0, 0, 0, 0.1));
    }
  }
  &.left {
    top: 50%;
    right: 100%;
    margin-right: $contentDefaultIndent;
    .content {
      transform: translate(-100%, -50%);
    }
    .arrow {
      transform: translate(0, -50%);
      border-width: 5px 0 5px 5px;
      border-color: transparent transparent transparent $uiWhiteColor;
      filter: drop-shadow(1px 0 1px rgba(0, 0, 0, 0.1));
    }
  }
}
