@import "nlib/config";

$transitionDuration: 0.15s;
$transitionEasing: ease-in-out;
$buttonDefaultBgColor: #e9ecef;
$buttonDefaultColor: #212529;
$buttonDarkColor: #4b4d59;

.button {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 2rem;
  padding: 0.375rem 0.75rem;
  transition:
    color $transitionDuration $transitionEasing,
    background-color $transitionDuration $transitionEasing,
    border-color $transitionDuration $transitionEasing,
    box-shadow $transitionDuration $transitionEasing;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  background-color: $uiWhiteColor;
  color: $buttonDefaultColor;
  font-size: $uiDesktopFontSize;
  font-weight: normal;
  line-height: 1.5;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  &.large {
    height: 2.5rem;
  }
  &.block {
    width: 100%;
  }
  .icon {
    margin-right: 0.3em;
    margin-left: -0.25rem;
    font-size: 1rem;
  }
  svg + span {
    margin-left: 0;
  }
  &:not(.light) {
    background-color: $buttonDefaultBgColor;
  }
  &:not([readonly]):not([disabled]):hover {
    background-color: darken($buttonDefaultBgColor, 5%);
    box-shadow: 0 5px 15px rgba($uiBlackColor, 0.05), 0 4px 10px rgba($buttonDefaultBgColor, 0.25);
  }
  &:not([readonly]):not([disabled]):focus {
    box-shadow:
      0 0 0 3px rgba($buttonDefaultBgColor, 0.15),
      0 3px 15px rgba($buttonDefaultBgColor, 0.2),
      0 2px 5px rgba($uiBlackColor, 0.1);
  }
  &:not([readonly]):not([disabled]):active {
    box-shadow: inset 0 3px 5px rgba($uiBlackColor, 0.15);
  }
  &.outline {
    border-color: $uiBorderColor;
    background-color: $uiWhiteColor;
  }
  &.outline,
  &.light {
    font-weight: 500;
    &:hover {
      background-color: lighten($buttonDefaultBgColor, 5%);
    }
  }
  &.dark {
    border-color: transparent;
    background-color: transparent;
    color: $buttonDarkColor;
    &:not(.light) {
      border-color: $buttonDarkColor;
    }
    &:not(.outline):not(.light) {
      background-color: $buttonDarkColor;
      color: $uiWhiteColor;
      &:hover {
        background-color: darken($buttonDarkColor, 5%);
        &:not(:active) {
          box-shadow: 0 5px 15px rgba($uiBlackColor, 0.05), 0 4px 10px rgba($buttonDarkColor, 0.25);
        }
      }
      &:focus:not(:active) {
        box-shadow:
          0 0 0 3px rgba($buttonDarkColor, 0.15),
          0 3px 15px rgba($buttonDarkColor, 0.2),
          0 2px 5px rgba($uiBlackColor, 0.1);
      }
    }
  }
  &.primary {
    background-color: $uiWhiteColor;
    color: $uiPrimaryColor;
    &:not(.light) {
      border-color: $uiPrimaryColor;
    }
    &:not(.outline):not(.light) {
      background-color: $uiPrimaryColor;
      color: $uiWhiteColor;
      &:hover {
        background-color: darken($uiPrimaryColor, 5%);
        &:not(:active) {
          box-shadow: 0 5px 15px rgba($uiBlackColor, 0.05), 0 4px 10px rgba($uiPrimaryColor, 0.25);
        }
      }
      &:focus:not(:active) {
        box-shadow:
          0 0 0 3px rgba($uiPrimaryColor, 0.15),
          0 3px 15px rgba($uiPrimaryColor, 0.2),
          0 2px 5px rgba($uiBlackColor, 0.1);
      }
    }
  }
  &.secondary {
    background-color: $uiWhiteColor;
    color: $uiAlternativeColor;
    &:not(.light) {
      border-color: $uiAlternativeColor;
    }
    &:not(.outline):not(.light) {
      background-color: $uiAlternativeColor;
      color: $uiWhiteColor;
      &:hover {
        background-color: darken($uiAlternativeColor, 5%);
        &:not(:active) {
          box-shadow: 0 5px 15px rgba($uiBlackColor, 0.05), 0 4px 10px rgba($uiAlternativeColor, 0.25);
        }
      }
      &:focus:not(:active) {
        box-shadow:
          0 0 0 3px rgba($uiAlternativeColor, 0.15),
          0 3px 15px rgba($uiAlternativeColor, 0.2),
          0 2px 5px rgba($uiBlackColor, 0.1);
      }
    }
  }
  &.success {
    border-color: transparent;
    background-color: $uiWhiteColor;
    color: $uiPositiveColor;
    &:not(.light) {
      border-color: $uiPositiveColor;
    }
    &:not(.outline):not(.light) {
      background-color: $uiPositiveColor;
      color: $uiWhiteColor;
      &:hover {
        background-color: darken($uiPositiveColor, 5%);
        &:not(:active) {
          box-shadow: 0 5px 15px rgba($uiBlackColor, 0.05), 0 4px 10px rgba($uiPositiveColor, 0.25);
        }
      }
      &:focus:not(:active) {
        box-shadow:
          0 0 0 3px rgba($uiPositiveColor, 0.15),
          0 3px 15px rgba($uiPositiveColor, 0.2),
          0 2px 5px rgba($uiBlackColor, 0.1);
      }
    }
  }
  &.danger {
    border-color: transparent;
    background-color: $uiWhiteColor;
    color: $uiNegativeColor;
    &:not(.light) {
      border-color: $uiNegativeColor;
    }
    &:not(.outline):not(.light) {
      background-color: $uiNegativeColor;
      color: $uiWhiteColor;
      &:hover {
        background-color: darken($uiNegativeColor, 5%);
        &:not(:active) {
          box-shadow: 0 5px 15px rgba($uiBlackColor, 0.05), 0 4px 10px rgba($uiNegativeColor, 0.25);
        }
      }
      &:focus:not(:active) {
        box-shadow:
          0 0 0 3px rgba($uiNegativeColor, 0.15),
          0 3px 15px rgba($uiNegativeColor, 0.2),
          0 2px 5px rgba($uiBlackColor, 0.1);
      }
    }
  }
  &.accent {
    border-color: transparent;
    background-color: $uiWhiteColor;
    color: $uiAccentColor;
    &:not(.light) {
      border-color: $uiAccentColor;
    }
    &:not(.outline):not(.light) {
      background-color: $uiAccentColor;
      color: $uiWhiteColor;
      &:hover {
        background-color: darken($uiAccentColor, 5%);
        &:not(:active) {
          box-shadow: 0 5px 15px rgba($uiBlackColor, 0.05), 0 4px 10px rgba($uiAccentColor, 0.25);
        }
      }
      &:focus:not(:active) {
        box-shadow:
          0 0 0 3px rgba($uiAccentColor, 0.15),
          0 3px 15px rgba($uiAccentColor, 0.2),
          0 2px 5px rgba($uiBlackColor, 0.1);
      }
    }
  }
  &.warning {
    border-color: transparent;
    background-color: $uiWhiteColor;
    color: $uiWarningColor;
    &:not(.light) {
      border-color: $uiWarningColor;
    }
    &:not(.outline):not(.light) {
      background-color: $uiWarningColor;
      color: $uiWhiteColor;
      &:hover {
        background-color: darken($uiWarningColor, 5%);
        &:not(:active) {
          box-shadow: 0 5px 15px rgba($uiBlackColor, 0.05), 0 4px 10px rgba($uiWarningColor, 0.25);
        }
      }
      &:focus:not(:active) {
        box-shadow:
          0 0 0 3px rgba($uiWarningColor, 0.15),
          0 3px 15px rgba($uiWarningColor, 0.2),
          0 2px 5px rgba($uiBlackColor, 0.1);
      }
    }
  }
  &.highlight {
    border-color: transparent;
    background-color: $uiWhiteColor;
    color: $uiLightBlueColor;
    &:not(.light) {
      border-color: $uiLightBlueColor;
    }
    &:not(.outline):not(.light) {
      background-color: $uiLightBlueColor;
      color: $uiWhiteColor;
      &:hover {
        background-color: darken($uiLightBlueColor, 5%);
        &:not(:active) {
          box-shadow: 0 5px 15px rgba($uiBlackColor, 0.05), 0 4px 10px rgba($uiLightBlueColor, 0.25);
        }
      }
      &:focus:not(:active) {
        box-shadow:
          0 0 0 3px rgba($uiLightBlueColor, 0.15),
          0 3px 15px rgba($uiLightBlueColor, 0.2),
          0 2px 5px rgba($uiBlackColor, 0.1);
      }
    }
  }
  &.loading::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation: preloader 1s linear infinite;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2));
    background-size: 200%;
  }
}
@keyframes preloader {
  from {
    background-position: 100% 0;
  }
  to {
    background-position: -100% 0;
  }
}
[data-mobile]:not([data-old-ui]) {
  .button {
    height: 3rem;
    border-width: 0.125rem;
    font-size: $uiMobileFontSize;
    &.small {
      height: 2.4rem;
    }
  }
}
