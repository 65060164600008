@import "nlib/config";

.dropDownMenuDivider {
  position: relative;
  margin: $contentDefaultIndent 0;
  border-bottom: 1px solid $uiBorderColor;
  .label {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0 $contentDefaultIndent;
    transform: translate(-50%, -50%);
    background-color: $uiWhiteColor;
    font-size: 0.625rem;
    text-transform: uppercase;
    white-space: nowrap;
  }
}
