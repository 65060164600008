@import "nlib/config";

.appModal {
  .contentClassName {
    padding-bottom: $contentDefaultIndent !important;
  }
  .content {
    max-height: 20rem;
    overflow: auto;
    word-break: break-word;
    white-space: pre-wrap;
  }
  .actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 $contentDefaultIndent * -1;
    padding-top: $contentDefaultIndent * 2;
    button {
      width: fit-content;
      min-width: 6rem;
      max-width: 16rem;
      margin: $contentDefaultIndent $contentDefaultIndent / 2;
    }
  }
}
