@import "nlib/config";

.dropDownMenuItem {
  display: flex;
  align-items: center;
  min-height: 2rem;
  padding: $contentDefaultIndent;
  overflow: hidden;
  transition: background-color $transitionDuration;
  font-size: $uiDesktopFontSize;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.clickable {
    cursor: pointer;
  }
  &:not([disabled]) {
    &.clickable:hover {
      background-color: rgba($uiBorderColor, 0.5);
    }
    &.selected {
      background-color: rgba($uiBorderColor, 1);
      font-weight: 500;
    }
    &.active {
      background-color: $uiBorderColor;
    }
  }
}
[data-mobile]:not([data-old-ui]) {
  .dropDownMenuItem {
    min-height: 3rem;
    font-size: $uiMobileFontSize;
  }
}
