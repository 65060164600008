@import "nlib/config";

.datePickerDays {
  min-height: 22.5rem;
  .dayNames {
    display: flex;
    align-items: center;
    height: 2rem;
    margin-bottom: $contentDefaultIndent / 2;
    .dayName {
      flex: 1;
      color: $uiDarkColor;
      font-size: 0.75rem;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
      user-select: none;
    }
  }
  .days {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
