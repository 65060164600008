@import "nlib/config";

.datePickerMonth {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $fullWidth / 3;
  height: 3rem;
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
  border-radius: $uiBorderRadius;
  font-size: 0.875rem;
  cursor: pointer;
  user-select: none;
  &.selected {
    background-color: $uiPrimaryColor;
    color: $uiWhiteColor;
  }
  &.disabled {
    cursor: default;
  }
  &:not(.disabled) {
    &:hover {
      background-color: rgba($uiBorderColor, 0.5);
      &.selected {
        background-color: rgba($uiPrimaryColor, 0.9);
      }
    }
  }
}
