@import "nlib/config";

.autoCompleteInput {
  position: relative;
  width: 100%;
  font-size: $uiDesktopFontSize;
  .disabled {}
  [disabled] {
    &,
    * {
      &,
      &::before,
      &::after {
        cursor: default;
        pointer-events: all;
      }
    }
  }
  .toggle {
    display: flex;
    position: relative;
  }
  .input {
    width: 100%;
  }
  &:not(.disabled) {
    .input {
      input {
        padding-right: 1.75rem;
      }
    }
  }
  .caret {
    position: absolute;
    top: 1px;
    right: 0;
    bottom: 1px;
    width: 2.125rem;
    font-size: 1em;
  }
  &.smallSize {
    .caret {
      width: 1.825rem;
    }
  }
  .dropDown {
    min-width: 100%;
  }
}

.dropDown {
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 20rem;
  .label {
    padding: $contentDefaultIndent $contentDefaultIndent * 2 0;
    color: $uiNeutralColor;
  }
  .content {
    display: flex;
    height: 100%;
    padding: $contentDefaultIndent;
    overflow: hidden;
    .scroll {
      flex: auto;
      max-height: 14rem;
      overflow: auto;
      .listItem {
        .listItemInner {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .groupLabel {
    padding: $contentDefaultIndent / 2 $contentDefaultIndent;
    background-color: $uiBackgroundColor;
    font-size: 0.75rem;
    font-weight: bold;
  }
}

[data-mobile]:not([data-old-ui]) {
  .autoCompleteInput {
    .caret {
      width: 2.5rem;
    }
    .dropdown {
      .content {
        .scroll {
          max-height: 9rem;
        }
      }
    }
  }
}
