@import "nlib/config";

.tab {
  padding: $contentDefaultIndent $contentDefaultIndent * 1.5;
  color: $uiMediumDarkColor;
  font-size: 0.875rem;
  white-space: nowrap;
  cursor: pointer;
  svg {
    font-size: 1rem;
  }
  svg + span {
    margin-left: $contentDefaultIndent;
  }
  &:hover {
    background-color: rgba($uiDarkColor, 0.05);
  }
  &.active {
    box-shadow: inset 0 -1px 0 $uiPrimaryColor, 0 1px 0 $uiPrimaryColor;
    color: $uiFontColor;
    font-weight: 500;
    svg {
      color: $uiPrimaryColor;
    }
  }
}
