@import "nlib/config";

.counter {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 21px;
  height: $contentDefaultIndent * 2;
  margin-left: $contentDefaultIndent;
  padding: 0 0.125rem;
  border: 1px solid transparent;
  border-radius: 0.5625rem;
  background-color: $uiDarkColor;
  color: $uiWhiteColor;
  font-size: 0.625rem;
  font-weight: 500;
  line-height: $contentDefaultIndent * 2;
  &.negative {
    background-color: $uiNegativeColor;
    color: $uiWhiteColor;
  }
}
