@import "nlib/config";

.select {
  .toggle {
    &[disabled] {
      opacity: 1 !important;
    }
    .button {
      justify-content: flex-start;
      width: 100%;
      height: 2.5rem;
      border-color: $uiBorderColor;
      background-color: $uiWhiteColor;
      font-weight: normal;
      .iconBefore {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        align-items: center;
        height: 100%;
        padding-left: $contentDefaultIndent * 1.5;
        color: $uiPlaceholderColor;
        font-size: 1rem;
      }
      &.withCaret {
        padding-right: 0.5rem;
      }
      &.withIconBefore {
        padding-left: 2.125rem;
      }
      &.active {
        border-color: $uiPrimaryColor;
        .iconBefore {
          color: $uiPrimaryColor;
        }
      }
      &.valid {
        border-color: $uiPositiveColor;
        .iconBefore {
          color: $uiPositiveColor;
        }
      }
      &.invalid {
        .iconBefore {
          color: $uiNegativeColor;
        }
      }
      .label {
        overflow: hidden;
        color: $uiFontColor;
        text-overflow: ellipsis;
        &.placeholder {
          color: $uiPlaceholderColor;
        }
      }
      &[disabled] {
        opacity: 1 !important;
        background-color: $uiBackgroundColor;
      }
    }
  }
  .caret {
    padding-left: $contentDefaultIndent;
  }
  &.readOnly {
    .button {
      background-color: $uiBackgroundColor;
      cursor: default;
    }
  }
  .selectDropdownContent {
    width: 100%;
  }
}
.selectDropdownContent {
  padding: $contentDefaultIndent;
  .title {
    display: flex;
    align-items: center;
    padding: 0 $contentDefaultIndent;
    font-weight: 600;
  }
  .groupLabel {
    padding: $contentDefaultIndent / 2 $contentDefaultIndent;
    background-color: $uiBackgroundColor;
    font-size: 0.75rem;
    font-weight: bold;
  }
  .option {
    display: flex;
    overflow: hidden;
    .label {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.selected {
      font-weight: 500;
      .label {
        padding-right: $contentDefaultIndent;
      }
      .iconCheck {
        margin-left: auto;
        color: $uiPrimaryColor;
        font-size: 1rem;
      }
    }
  }
}

[data-mobile]:not([data-old-ui]) {
  .select {
    .toggle {
      .button {
        height: 3rem;
      }
    }
  }
  .selectDropdownContent {
    .title {
      display: flex;
      position: absolute;
      top: 0;
      right: 4rem;
      left: 0;
      align-items: center;
      height: 4rem;
      margin-bottom: $contentDefaultIndent;
      padding-left: $contentDefaultIndent * 2;
      font-size: 1rem;
      font-weight: 500;
      text-align: center;
    }
    .option {
      padding-right: $contentDefaultIndent * 2;
      padding-left: $contentDefaultIndent * 2;
      &.selected {
        .iconCheck {
          font-size: 1.25rem;
        }
      }
    }
  }
}
