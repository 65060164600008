@import "nlib/config";

.pagination {
  display: flex;
  .pages {
    display: flex;
  }
  .button {
    width: 2rem;
    padding-right: 0;
    padding-left: 0;
    + .button {
      margin-left: $contentDefaultIndent / 2;
    }
  }
}
[data-mobile]:not([data-old-ui]) {
  .pagination {
    display: flex;
    .button {
      width: 2.5rem;
      height: 2.5rem;
      border-color: $uiBorderColor;
    }
  }
}
