@import "nlib/config";

$animationDuration: 0.5s;

.preloaderDotted {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $uiPrimaryColor;
  font-size: 0.75rem;
  &.absolute,
  &.fixed {
    z-index: 9;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($uiWhiteColor, 0.4);
  }
  &.absolute {
    position: absolute;
  }
  &.fixed {
    position: fixed;
  }
  .animation {
    position: relative;
    span {
      display: inline-block;
      width: 1em;
      height: 1em;
      animation: flashing $animationDuration infinite linear alternate;
      border-radius: 50%;
      background-color: currentColor;
      + span {
        margin-left: 0.5em;
        animation-delay: $animationDuration / 2;
        + span {
          animation-delay: $animationDuration;
        }
      }
    }
  }
}

@keyframes flashing {
  0% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0.1;
  }
}
