@import "nlib/config";

.checkbox {
  display: inline-flex;
  position: relative;
  align-items: center;
  font-size: 1em;
  user-select: none;
  .input {
    display: none;
    visibility: hidden;
  }
  &:not(.readOnly) {
    cursor: pointer;
  }
  &:hover,
  &.readOnly {
    .box {
      background-color: lighten($uiBorderColor, 2%);
    }
  }
  .box {
    display: inline-block;
    position: relative;
    width: 0.875rem;
    min-width: 0.875rem;
    height: 0.875rem;
    transition: background-color $transitionDuration, border-color $transitionDuration;
    border: 1px solid $uiBorderColor;
    border-radius: $uiBorderRadius;
    background-color: $uiWhiteColor;
    color: $uiWhiteColor;
    pointer-events: none;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
    &:not([disabled]) {
      &.checked,
      &.indeterminate {
        border-color: $uiPrimaryColor;
        background-color: $uiPrimaryColor;
        svg {
          opacity: 1;
        }
      }
    }
  }
  &.readOnly {
    .box {
      &.checked,
      &.indeterminate {
        border-color: $uiMediumDarkColor;
        background-color: $uiMediumDarkColor;
      }
    }
  }
  .label {
    margin-left: $contentDefaultIndent;
  }
  &.alignRight {
    flex-direction: row-reverse;
    .box {
      margin-right: 0;
      margin-left: 0.3em;
    }
  }
  &.toggle {
    .box {
      position: relative;
      width: 2rem;
      min-width: 2rem;
      height: 1.125rem;
      border: none;
      border-radius: 0.5625rem;
      &:not(.checked) {
        border-color: lighten($uiNeutralColor, 10%);
        background-color: lighten($uiNeutralColor, 10%);
      }
      &::after {
        content: "";
        position: absolute;
        top: 0.125rem;
        left: 0.125rem;
        width: 0.875rem;
        height: 0.875rem;
        transition: transform 0.2s;
        border-radius: 50%;
        background-color: $uiWhiteColor;
      }
      &.checked {
        &::after {
          transform: translate(0.875rem, 0);
        }
      }
      svg {
        display: none;
      }
    }
    &.readOnly {
      .box {
        &:not(.checked) {
          border-color: lighten($uiNeutralColor, 15%);
          background-color: lighten($uiNeutralColor, 15%);
        }
      }
    }
  }
  &[disabled] {
    .box {
      background-color: $uiNeutralColor;
    }
  }
}
[data-mobile]:not([data-old-ui]) {
  .checkbox {
    .box {
      border-width: 0.125rem;
    }
  }
}
