@import "nlib/config";

.dropDownCaret {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 0 0 0 $contentDefaultIndent;
  font-size: 1rem;
  user-select: none;
  svg {
    min-width: 1rem;
    height: 1rem;
  }
  &.opened {
    svg {
      transform: rotate(180deg);
    }
  }
}
