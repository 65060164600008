@import "nlib/config";

.modalFooter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: $contentDefaultIndent -1 * $contentDefaultIndent -1 * $contentDefaultIndent;
  button {
    margin: $contentDefaultIndent;
    padding-right: $contentDefaultIndent * 3;
    padding-left: $contentDefaultIndent * 3;
  }
}

[data-mobile] {
  .modalFooter {
    margin: $contentDefaultIndent -1 * $contentDefaultIndent -1 * $contentDefaultIndent;
    button {
      padding-right: $contentDefaultIndent * 1.5;
      padding-left: $contentDefaultIndent * 1.5;
    }
  }
}
